<template>
  <cp-general-modal
    ref="issuerQuestion"
    :ok-title="getTitleName"
    size="lg"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="questionData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.question"
      name="question"
      :label="$t('issuerQuestions.questionsModal.input.question')"
    />

    <cp-input
      name="position"
      :label="$t('issuerQuestions.questionsModal.input.position')"
      input-type="number"
    />

    <cp-select
      v-model="model.section"
      name="section"
      :label="$t('issuerQuestions.questionsModal.input.section')"
      :options="sections"
    />

    <cp-text-area
      v-model="model.answer"
      name="answer"
      :label="$t('issuerQuestions.questionsModal.input.answer')"
      :rows="10"
    />
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import { CpInput, CpSelect, CpTextArea } from '~/components/common/standalone-components/inputs';
import { sectionLabels } from './options';

export default {
  name: 'IssuerQuestionsModal',

  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpTextArea,
  },

  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    questionData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      model: {},
      sections: Object.keys(sectionLabels).map(key => ({ value: key, i18nKey: sectionLabels[key] })),
    };
  },

  computed: {
    getTitleName() {
      return this.$t('issuerQuestions.questionsModal.title', [this.titleData.label]);
    },
  },

  watch: {
    questionData(val) {
      this.model = Object.assign({}, val);
    },
  },

  methods: {
    handleSubmit() {
      const action = this.titleData.type === 'Edit' ? 'update' : 'create';
      this.$emit('onSubmit', { action, data: this.model });
    },
  },
};
</script>
